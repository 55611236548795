import { Icon, Image } from "@chakra-ui/react";
import { HiUsers } from "react-icons/hi";
import {
  MdContacts,
  MdHome,
  MdInsertChartOutlined,
  MdLeaderboard,
  MdLock,
} from "react-icons/md";
// icon
import React from "react";
import { AiFillFolderOpen, AiOutlineMail } from "react-icons/ai";
import { FaCalendarAlt, FaRupeeSign, FaTasks, FaWpforms } from "react-icons/fa";
import { LuBuilding2 } from "react-icons/lu";
import { PiPhoneCallBold } from "react-icons/pi";
import { FaCreativeCommonsBy } from "react-icons/fa";
import { SiGooglemeet } from "react-icons/si";
import { ROLE_PATH } from "./roles";
import ChangeImage from "views/admin/image";
import Validation from "views/admin/validation";
import CustomField from "views/admin/customField";
import TableField from "views/admin/tableField";
import { TbExchange, TbTableColumn } from "react-icons/tb";
import { GrValidate } from "react-icons/gr";
import { VscFileSubmodule } from "react-icons/vsc";
import { FiEye } from "react-icons/fi";
import { EditIcon, LockIcon } from "@chakra-ui/icons";

import benthon from "Images/benthon.png";
import HomePage from "views/admin/home";
// Home
// const HomePage = React.lazy(() => import("views/admin/home"));

const HomeDashboard = React.lazy(() => import("views/admin/home"));
//Department
const Department = React.lazy(() => import("views/admin/department"));
//Job Profile
const JobProfile = React.lazy(() => import("views/admin/jobProfile"));
// Admin Imports
const MainDashboard = React.lazy(() => import("views/admin/default"));

const ShareByMePassword = React.lazy(() => import("views/admin/ShareByMePassword"));

const ShareWithMePassword = React.lazy(() => import("views/admin/ShareWithMePassword"));

const MyPassword = React.lazy(() => import("views/admin/MyPassword"));
const passwordView = React.lazy(() =>
  import("views/admin/passwords/AllPasswordView")
);
const sharedByMePasswordView = React.lazy(() =>
  import("views/admin/passwords/SharedByMeView")
);
const sharedWithMePasswordView = React.lazy(() =>
  import("views/admin/passwords/SharedWithMeView")
);
const allPasswordShare = React.lazy(() =>
  import("views/admin/passwords/AllPasswordShare")
);
// All Password
// My component
const SSORedirect = React.lazy(() => import("views/admin/SSORedirect"));
const Contact = React.lazy(() => import("views/admin/contact"));
const ContactView = React.lazy(() => import("views/admin/contact/View"));
const ContactImport = React.lazy(() =>
  import("views/admin/contact/components/ContactImport")
);

const User = React.lazy(() => import("views/admin/users"));
const UserView = React.lazy(() => import("views/admin/users/View"));

// const Property = React.lazy(() => import("views/admin/property"));
// const PropertyView = React.lazy(() => import("views/admin/property/View"));
// const PropertyImport = React.lazy(() => import("views/admin/property/components/PropertyImport"))

const Lead = React.lazy(() => import("views/admin/lead"));
const LeadView = React.lazy(() => import("views/admin/lead/View"));
const LeadImport = React.lazy(() =>
  import("views/admin/lead/components/LeadImport")
);

const Communication = React.lazy(() => import("views/admin/communication"));

const Task = React.lazy(() => import("views/admin/task"));
const TaskView = React.lazy(() =>
  import("views/admin/task/components/taskView")
);
const Calender = React.lazy(() => import("views/admin/calender"));
// const Payments = React.lazy(() => import("views/admin/payments"));
const Role = React.lazy(() => import("views/admin/role"));

const Document = React.lazy(() => import("views/admin/document"));

const EmailHistory = React.lazy(() => import("views/admin/emailHistory"));
const EmailHistoryView = React.lazy(() =>
  import("views/admin/emailHistory/View")
);

const Meeting = React.lazy(() => import("views/admin/meeting"));
const MettingView = React.lazy(() => import("views/admin/meeting/View"));

const PhoneCall = React.lazy(() => import("views/admin/phoneCall"));
const CustomDashboard = React.lazy(() => import("views/admin/CustomDashboard"));
const PhoneCallView = React.lazy(() => import("views/admin/phoneCall/View"));

const Report = React.lazy(() => import("views/admin/reports"));

// Auth Imports
const SignInCentered = React.lazy(() => import("views/auth/signIn"));
const ForgotPassword = React.lazy(() => import("views/auth/forgotPassword"));
const ResetPassword = React.lazy(() => import("views/auth/resetPassword"));
// admin setting
const AdminSetting = React.lazy(() => import("views/admin/adminSetting"));
const validation = React.lazy(() => import("views/admin/validation"));
const module = React.lazy(() => import("views/admin/moduleName"));

export const homeRoutes = [
  // ========================== Onboarding ==========================

  {
    name: "Sign In",
    layout: "/auth",
    path: "/auth/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/auth/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/auth/reset-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
  },
  // ========================== Sub-Application Routes ==========================

  {
    name: "Home",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: HomePage,
  },
  {
    name: "Pulse CRM",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/default",
    icon: (
      <Image
        objectFit="cover"
        src={benthon}
        style={{ opacity: "1" }}
        h="20px"
        w="20px"
      />
    ),
    component: MainDashboard,
  },
  {
    name: "Vault",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/password/my-password",
    icon: <Icon as={LockIcon} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Recruitment",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/recruit/jobProfile",
    icon: <Icon as={MdContacts} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Departments",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/department",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
];
const routes = [
  // ========================== Dashboard ==========================
  {
    name: "Home",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' title="Dashboard" /> ,
    component: MainDashboard,
  },
  
  // ------------- redirect ------------------------
  // {
  //   name: "Redirect Spa",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/redirect_spa",
  //   icon: (
  //     <Icon as={MdLeaderboard} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: SSORedirect,
  // },
  // ========================== Admin Layout ==========================
  // ------------- lead Routes ------------------------
  {
    name: "Leads",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/lead",
    icon: (
      <Icon as={MdLeaderboard} width="20px" height="20px" color="inherit" />
    ),
    component: Lead,
  },
  {
    name: "Leads",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "/crm/lead",
    parentName: "Leads",
    path: "/crm/leadView/:id",
    component: LeadView,
  },
  {
    name: "Lead Import",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "/crm/lead",
    parentName: "Leads",
    path: "/crm/leadImport",
    component: LeadImport,
  },
  // --------------- contact Routes --------------------
  {
    name: "Contacts",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/contacts",
    icon: <Icon as={MdContacts} width="20px" height="20px" color="inherit" />,
    component: Contact,
  },
  {
    name: "Contacts",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "/crm/contacts",
    parentName: "Contacts",
    path: "/crm/contactView/:id",
    component: ContactView,
  },
  {
    name: "Contact Import",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    both: true,
    under: "/crm/contacts",
    parentName: "Contacts",
    path: "/crm/contactImport",
    component: ContactImport,
  },
  // ------------- Property Routes ------------------------
  // {
  //   name: "Properties",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/properties",
  //   icon: <Icon as={LuBuilding2} width='20px' height='20px' color='inherit' />,
  //   component: Property,
  // },
  // {
  //   name: "Properties ",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   parentName: "Properties",
  //   under: "properties",
  //   path: "/propertyView/:id",
  //   component: PropertyView,
  // },
  // {
  //   name: "Property Import",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   both: true,
  //   under: "properties",
  //   parentName: "Properties",
  //   path: "/propertyImport",
  //   component: PropertyImport,
  // },
  // -----------------------------Admin setting-------------------------------------
  // {
  //   name: "Admin Setting",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   parentName: "admin",
  //   under: "admin",
  //   path: "/crm/admin-setting",
  //   component: AdminSetting,
  // },

  // ------------- Task Routes ------------------------
  {
    name: "Tasks",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/task",
    icon: <Icon as={FaTasks} width="20px" height="20px" color="inherit" />,
    component: Task,
  },
  {
    name: "Tasks",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "/crm/task",
    parentName: "Tasks",
    path: "/crm/view/:id",
    component: TaskView,
  },
  // ------------- Meeting Routes ------------------------
  {
    name: "Meetings",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/metting",
    icon: <Icon as={SiGooglemeet} width="20px" height="20px" color="inherit" />,
    component: Meeting,
  },
  {
    name: "Meetings ",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "/crm/metting",
    parentName: "Meetings",
    path: "/crm/metting/:id",
    component: MettingView,
  },
  // ------------- Phone Routes ------------------------
  {
    name: "Calls",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/phone-call",
    icon: (
      <Icon as={PiPhoneCallBold} width="20px" height="20px" color="inherit" />
    ),
    component: PhoneCall,
  },
  {
    name: "Calls",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "/crm/phone-call",
    parentName: "Calls",
    path: "/crm/phone-call/:id",
    component: PhoneCallView,
  },
  // ------------- Custom Dashboard Routes ------------------------
  {
    name: "Customize Dashboard",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/custom-dashboard",
    icon: <Icon as={EditIcon} width="20px" height="20px" color="inherit" />,
    component: CustomDashboard,
  },
  // ------------- Email Routes------------------------
  {
    // separator: 'History',
    name: "Emails",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/email",
    icon: (
      <Icon as={AiOutlineMail} width="20px" height="20px" color="inherit" />
    ),
    component: EmailHistory,
  },
  {
    name: "Emails ",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Emails",
    parentName: "Emails",
    path: "/crm/email/:id",
    component: EmailHistoryView,
  },
  // ------------- Calender Routes ------------------------
  {
    name: "Calender",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/calender",
    icon: (
      <Icon as={FaCalendarAlt} width="20px" height="20px" color="inherit" />
    ),
    component: Calender,
  },
  // ------------- Payments Routes ------------------------
  // {
  //   name: "Payments",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/payments",
  //   icon: <Icon as={FaRupeeSign} width='20px' height='20px' color='inherit' />,
  //   component: Payments,
  // },

  // ------------- Roles Routes ------------------------
  // {
  //   name: "Roles",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/crm/role",
  //   under: "role",
  //   icon: (
  //     <Icon
  //       as={FaCreativeCommonsBy}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: Role,
  // },
  // {
  //   name: "Custom Fields",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/crm/custom-Fields",
  //   under: "customField",
  //   icon: <Icon as={FaWpforms} width="20px" height="20px" color="inherit" />,
  //   component: CustomField,
  // },
  // {
  //   name: "Change Images",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/crm/change-images",
  //   under: "image",
  //   icon: <Icon as={TbExchange} width="20px" height="20px" color="inherit" />,
  //   component: ChangeImage,
  // },
  // {
  //   name: "Validation",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/crm/validations",
  //   under: "Validation",
  //   icon: <Icon as={GrValidate} width="20px" height="20px" color="inherit" />,
  //   component: Validation,
  // },
  // {
  //   name: "Table Fields",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/crm/table-field",
  //   under: "tableField",
  //   icon: (
  //     <Icon as={TbTableColumn} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: TableField,
  // },
  // {
  //   name: "Module",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/crm/module",
  //   under: "module",
  //   icon: (
  //     <Icon as={VscFileSubmodule} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: module,
  // },
  // // ------------- Text message Routes ------------------------
  // {
  //   name: "Text Msg",
  //   layout: [ROLE_PATH.admin, ROLE_PATH.user],
  //
  //   path: "/text-msg",
  //   icon: <Icon as={MdOutlineMessage} width='20px' height='20px' color='inherit' />,
  //   component: TextMsg,
  // },
  // {
  //   name: "Text Msg View",
  //   layout: [ROLE_PATH.admin, ROLE_PATH.user],
  //
  //   under: "text-msg",
  //   path:  text-msg/:id",
  //   component: TextMsgView,
  // },
  // ------------- Document Routes ------------------------
  {
    name: "Documents",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/documents",
    icon: (
      <Icon as={AiFillFolderOpen} width="20px" height="20px" color="inherit" />
    ),
    component: Document,
  },

  // ----------------- Reporting Layout -----------------
  {
    name: "Reporting and Analytics",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/reporting-analytics",
    icon: (
      <Icon
        as={MdInsertChartOutlined}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Report,
  },
  // ------------- user Routes ------------------------
  {
    name: "Users",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/crm/user",
    under: "user",
    icon: <Icon as={HiUsers} width="20px" height="20px" color="inherit" />,
    component: User,
  },
  {
    name: "User View",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    parentName: "Email",
    under: "user",
    path: "/crm/userView/:id",
    component: UserView,
  },
  // ========================== User layout ==========================

  // ========================== auth layout ==========================
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/crm/reset-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
  },
];
export const vaultRoutes = [
  // ------------- All Password Routes ------------------------
  {
    name: "My Password",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/password/my-password",
    icon: <Icon as={LockIcon} width="20px" height="20px" color="inherit" />,
    component: MyPassword,
  },
  {
    name: "Share With Me",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/password/share-with-me",
    icon: <Icon as={LockIcon} width="20px" height="20px" color="inherit" />,
    component: ShareWithMePassword,
  },
  {
    name: "Share By Me",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/password/share-by-me",
    icon: <Icon as={LockIcon} width="20px" height="20px" color="inherit" />,
    component: ShareByMePassword,
  },
  {
    name: "View Password ",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "password/my-password",
    parentName: "All Password",
    path: "/password/passwordList/:id",
    component: passwordView,
  },

  {
    name: "Share Password ",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "password/my-password",
    parentName: "All Password",
    path: "/password/viewSharedByMePassword/:id",
    component: sharedByMePasswordView,
  },

  {
    name: "View Password ",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "password/my-password",
    parentName: "All Password",
    path: "/password/viewSharedWithMePassword/:id",
    component: sharedWithMePasswordView,
  },

  {
    name: "Share Password ",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "password/my-password",
    parentName: "All Password",
    path: "/password/viewAllSharePassword/:id",
    component: allPasswordShare,
  },
];
export const commonRoutes = [
   // -----------------------------Admin setting-------------------------------------
   {
    name: "Admin Setting",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    parentName: "admin",
    under: "/crm",
    path: "/admin-setting",
    component: AdminSetting,
  },
  // ------------- Roles Routes ------------------------
  {
    name: "Roles",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/role",
    under: "role",
    icon: (
      <Icon
        as={FaCreativeCommonsBy}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Role,
  },
  {
    name: "Custom Fields",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/custom-Fields",
    under: "customField",
    icon: <Icon as={FaWpforms} width="20px" height="20px" color="inherit" />,
    component: CustomField,
  },
  {
    name: "Change Images",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/change-images",
    under: "image",
    icon: <Icon as={TbExchange} width="20px" height="20px" color="inherit" />,
    component: ChangeImage,
  },
  {
    name: "Validation",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/validations",
    under: "Validation",
    icon: <Icon as={GrValidate} width="20px" height="20px" color="inherit" />,
    component: Validation,
  },
  {
    name: "Table Fields",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/table-field",
    under: "tableField",
    icon: (
      <Icon as={TbTableColumn} width="20px" height="20px" color="inherit" />
    ),
    component: TableField,
  },
  {
    name: "Module",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/module",
    under: "module",
    icon: (
      <Icon as={VscFileSubmodule} width="20px" height="20px" color="inherit" />
    ),
    component: module,
  },
    // ------------- user Routes ------------------------
    {
      name: "Users",
      layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
      path: "/user",
      under: "user",
      icon: <Icon as={HiUsers} width="20px" height="20px" color="inherit" />,
      component: User,
    },
    {
      name: "User View",
      layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
      parentName: "Email",
      under: "user",
      path: "/userView/:id",
      component: UserView,
    },
    // ------------- redirect ------------------------
 
    {
      name: "Redirect Spa",
      layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
      path: "/redirect_spa",
      icon: (
        <Icon as={MdLeaderboard} width="20px" height="20px" color="inherit" />
      ),
      component: SSORedirect,
    },
   
];
export const departmentRoutes=[
    // ------------- Department Routes ------------------------
    {
      name: "Departments",
      layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
      path: "/department",
      icon: <Icon as={MdLeaderboard} width='20px' height='20px' color='inherit' />,
      component: Department,
    },
]
export const recruitmentRoutes=[
  // ------------- Department Routes ------------------------
  {
    name: "Job Profile",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/recruit/jobProfile",
    icon: <Icon as={MdLeaderboard} width='20px' height='20px' color='inherit' />,
    component: JobProfile,
  },
]



export default routes;
