import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Button, Flex, IconButton, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, Text, Tooltip } from '@chakra-ui/react';
import React from 'react'
import { useEffect } from 'react';
import colors from 'theme/foundations/colors';

const Pagination = (props) => {
    const { gotoPage, gopageValue, setGopageValue, pageCount, canPreviousPage, previousPage, canNextPage, pageOptions, setPageSize, nextPage, pageSize, pageIndex, dataLength } = props
    console.log("propsss", props)
    useEffect(() => {
        setGopageValue(1)
    }, [])

    console.log("pageoptions", pageOptions)

    const getPageNumbers = () => {
        const totalPages = pageCount;
        const currentPage = pageIndex + 1;
        const pageNumbers = [];

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            pageNumbers.push(1);

            if (currentPage > 3) {
                pageNumbers.push("...");
            }

            let startPage = Math.max(2, currentPage - 1);
            let endPage = Math.min(totalPages - 1, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(i);
            }

            if (currentPage < totalPages - 2) {
                pageNumbers.push("...");
            }

            pageNumbers.push(totalPages);
        }

        return pageNumbers;
    };

    const startResult = pageIndex * pageSize + 1;

    return (

        <>
            <Flex className='paginationContainer' justifyContent={pageOptions?.length !== 1 ? "space-between" : "end"} mt={2} alignItems="center" >
                {pageOptions?.length !== 1 &&
                    <div>
                        <Text className='paginationMsg' flexShrink="0" mr={8}>
                            Showing  <span>{startResult}</span> out of <span>{dataLength}</span> results
                        </Text>
                    </div>
                }

                <div className='pageContainer'>
                    {pageOptions?.length !== 1 &&
                        <IconButton
                            className="paginationBtn paginationControllerBtn"
                            onClick={previousPage}
                            isDisabled={!canPreviousPage}
                            icon={<ChevronLeftIcon h={6} w={6} />}
                            mr={2}
                        />
                    }


                    {getPageNumbers().map((num, index) => (
                        <Button
                            className="paginationBtn"
                            key={index}
                            onClick={() => num !== "..." && gotoPage(num - 1)}
                            isDisabled={num === "..."}
                            border={num === pageIndex + 1 ? "none" : `1px solid ${colors.borderColor}`}
                            bg={num === pageIndex + 1 ? colors.cta.primary : colors.white}
                            color={num === pageIndex + 1 ? colors.white : colors.text.primary}
                            fontWeight={num === pageIndex + 1 ? "700" : "400"}
                            _hover={{
                                bg: num !== pageIndex + 1 ? colors.btnHoverBg : colors.cta.primary,
                            }}
                            mx={1}
                        >
                            {num}
                        </Button>
                    ))}

                    {pageOptions?.length !== 1 &&

                        <IconButton
                            className="paginationBtn paginationControllerBtn"
                            onClick={nextPage}
                            isDisabled={!canNextPage}
                            icon={<ChevronRightIcon h={6} w={6} />}
                            ml={2}
                        />
                    }

                    <Select
                        ml={4}
                        w={32}
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        {[5, 10, 20, 30, 40, 50].map((size) => (
                            <option key={size} value={size}>
                                Show {size}
                            </option>
                        ))}
                    </Select>
                </div>

            </Flex>

        </>
    )
}

export default Pagination
